$(document).ready(() => {
  const holder = '.ucpt-holder';
  const $holderInput = $('.ucpt-holder__input');

  holderFilledClass($holderInput);

  $holderInput.on('change', (e) => {
    holderFilledClass($(e.currentTarget));
  });

  function holderFilledClass(e) {
    if (!(e.val() === null || e.val() === '')) {
      e.parents(holder).addClass('ucpt-holder--filled');
    } else {
      e.parents(holder).removeClass('ucpt-holder--filled');
    }
  }
});
