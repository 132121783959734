/* eslint-disable no-undef */
$(document).ready(() => {
  // == Ajax request management
  let currentRequest = null;

  $(document).on('change', 'select.ucpt-search__select:not(.ucpt-search__select--model):not(.ucpt-search__select--reference)', (e) => {
    $(e.currentTarget).parents('.ucpt-search').addClass('ucpt-spinner--is-spining');
  });

  /* ajax script for getting  Manufacturer data */
  $(document).on('change', 'select.ucpt-search__select--type', (e) => {
    const $contextParent = $(e.currentTarget).parents('.ucpt-search');
    $contextParent.find('select.ucpt-search__select--manufacturer,select.ucpt-search__select--year,select.ucpt-search__select--model').html('').prop('disabled', true).trigger('change.select2');

    const typeID = $(e.currentTarget).val();

    if (typeID) {
      let pageName = '';

      if ((typeof prestashop) !== 'undefined' && (typeof prestashop.page) !== 'undefined' && (typeof prestashop.page.page_name) !== 'undefined') {
        pageName = prestashop.page.page_name;
      }

      currentRequest = $.ajax({
        type: 'GET',
        dataType: 'json',
        beforeSend() {
          if (currentRequest != null) {
            currentRequest.abort();
            currentRequest = null;
          }
        },
        url: `/module/ukooparts/front?action=getBrand&ajax=true&type=${typeID}&page_name=${pageName}`,
        success(result) {
          $contextParent.find('select.ucpt-search__select--manufacturer').empty();
          let optionsHighlight = `<optgroup label="${top_brands}">`;
          let optionsNormal = `<optgroup label="${our_other_brands}">`;
          $.each(result.highlight, (k, v) => {
            optionsHighlight += `<option value="${v.id}">${v.name}</option>`;
          });
          $.each(result.normal, (k, v) => {
            optionsNormal += `<option value="${v.id}">${v.name}</option>`;
          });
          optionsHighlight += '</optgroup>';
          optionsNormal += '</optgroup>';

          if (result.highlight !== undefined && result.highlight.length) {
            $contextParent.find('select.ucpt-search__select--manufacturer').append(optionsHighlight);
          }
          if (result.normal !== undefined && result.normal.length) {
            $contextParent.find('select.ucpt-search__select--manufacturer').append(optionsNormal);
          }
          $contextParent.find('select.ucpt-search__select--manufacturer').prepend('<option></option>');
          $contextParent.find('select.ucpt-search__select--manufacturer').val(0);
          $contextParent.find('select.ucpt-search__select--manufacturer').prop('disabled', false).trigger('change.select2');

          $contextParent.removeClass('ucpt-spinner--is-spining');
        },
      });
    }
  });
  /* ajax script to get Reference data */
  $(document).on('change', 'select.ucpt-search__select--manufacturer', (e) => {
    const $contextParent = $(e.currentTarget).parents('.ucpt-search');
    $contextParent.find('select.ucpt-search__select--reference').html('').prop('disabled', true).trigger('change.select2');
    const typeID = $contextParent.find('select.ucpt-search__select--type').val();
    const marqueID = $(e.currentTarget).val();

    if (marqueID) {
      let pageName = '';

      if ((typeof prestashop) !== 'undefined' && (typeof prestashop.page) !== 'undefined' && (typeof prestashop.page.page_name) !== 'undefined') {
        pageName = prestashop.page.page_name;
      }

      currentRequest = $.ajax({
        type: 'GET',
        dataType: 'json',
        beforeSend() {
          if (currentRequest != null) {
            currentRequest.abort();
            currentRequest = null;
          }
        },
        url: `/module/ukooparts/front?action=getModels&ajax=true&type=${typeID}&brand=${marqueID}&page_name=${pageName}`,
        success(result) {
          $contextParent.find('select.ucpt-search__select--reference').empty();
          $.each(result, (displacement, models) => {
            let displacementOptions = '';
            $.each(models, (k, v) => {
              displacementOptions += `<option value="${v.id}">${v.reference}</option>`;
            });
            $contextParent.find('select.ucpt-search__select--reference').append(displacementOptions);
          });
          $contextParent.find('select.ucpt-search__select--reference').prepend('<option></option>');
          $contextParent.find('select.ucpt-search__select--reference').val(0);
          $contextParent.find('select.ucpt-search__select--reference').prop('disabled', false).trigger('change.select2');

          $contextParent.removeClass('ucpt-spinner--is-spining');
        },
      });
    }
  });
  /* ajax script to get manufacturer-year data */
  $(document).on('change', 'select.ucpt-search__select--type-year', (e) => {
    const $contextParent = $(e.currentTarget).parents('.ucpt-search');
    $contextParent.find('select.ucpt-search__select--manufacturer-year,select.ucpt-search__select--year,select.ucpt-search__select--model').html('').prop('disabled', true).trigger('change.select2');

    const typeID = $(e.currentTarget).val();

    if (typeID) {
      let pageName = '';

      if ((typeof prestashop) !== 'undefined' && (typeof prestashop.page) !== 'undefined' && (typeof prestashop.page.page_name) !== 'undefined') {
        pageName = prestashop.page.page_name;
      }

      currentRequest = $.ajax({
        type: 'GET',
        dataType: 'json',
        beforeSend() {
          if (currentRequest != null) {
            currentRequest.abort();
            currentRequest = null;
          }
        },
        url: `/module/ukooparts/front?action=getBrand&ajax=true&type=${typeID}&page_name=${pageName}`,
        success(result) {
          $contextParent.find('select.ucpt-search__select--manufacturer-year').empty();
          let optionsHighlight = `<optgroup label="${top_brands}">`;
          let optionsNormal = `<optgroup label="${our_other_brands}">`;
          $.each(result.highlight, (k, v) => {
            optionsHighlight += `<option value="${v.id}">${v.name}</option>`;
          });
          $.each(result.normal, (k, v) => {
            optionsNormal += `<option value="${v.id}">${v.name}</option>`;
          });
          optionsHighlight += '</optgroup>';
          optionsNormal += '</optgroup>';

          if (result.highlight !== undefined && result.highlight.length) {
            $contextParent.find('select.ucpt-search__select--manufacturer-year').append(optionsHighlight);
          }
          if (result.normal !== undefined && result.normal.length) {
            $contextParent.find('select.ucpt-search__select--manufacturer-year').append(optionsNormal);
          }

          $contextParent.find('select.ucpt-search__select--manufacturer-year').prepend('<option></option>');
          $contextParent.find('select.ucpt-search__select--manufacturer-year').val(0);
          $contextParent.find('select.ucpt-search__select--manufacturer-year').prop('disabled', false).trigger('change.select2');

          $contextParent.removeClass('ucpt-spinner--is-spining');
        },
      });
    }
  });
  /* ajax script to get years data */
  $(document).on('change', 'select.ucpt-search__select--manufacturer-year', (e) => {
    const $contextParent = $(e.currentTarget).parents('.ucpt-search');
    $contextParent.find('select.ucpt-search__select--year,select.ucpt-search__select--model').html('').prop('disabled', true).trigger('change.select2');

    const typeID = $contextParent.find('select.ucpt-search__select--type-year').val();
    console.log(typeID);
    const marqueID = $(e.currentTarget).val();

    if (marqueID) {
      let pageName = '';

      if ((typeof prestashop) !== 'undefined' && (typeof prestashop.page) !== 'undefined' && (typeof prestashop.page.page_name) !== 'undefined') {
        pageName = prestashop.page.page_name;
      }

      currentRequest = $.ajax({
        type: 'GET',
        dataType: 'json',
        beforeSend() {
          if (currentRequest != null) {
            currentRequest.abort();
            currentRequest = null;
          }
        },
        url: `/module/ukooparts/front?action=getYears&ajax=true&type=${typeID}&brand=${marqueID}&page_name=${pageName}`,
        success(result) {
          $contextParent.find('select.ucpt-search__select--year').empty();
          $.each(result, (k, v) => {
            $contextParent.find('select.ucpt-search__select--year').append(' ', `<option value="${v.year}">${v.year}</option>`);
          });
          $contextParent.find('select.ucpt-search__select--year').prepend('<option></option>');
          $contextParent.find('select.ucpt-search__select--year').val(0);
          $contextParent.find('select.ucpt-search__select--year').prop('disabled', false).trigger('change.select2');

          $contextParent.removeClass('ucpt-spinner--is-spining');
        },
      });
    }
  });

  /* ajax script to get model data */
  $(document).on('change', 'select.ucpt-search__select--year', (e) => {
    const $contextParent = $(e.currentTarget).parents('.ucpt-search');
    $contextParent.find('select.ucpt-search__select--model').html('').prop('disabled', true).trigger('change.select2');

    const typeID = $contextParent.find('select.ucpt-search__select--type-year').val();
    const brandID = $contextParent.find('select.ucpt-search__select--manufacturer-year').val();
    const yearID = $(e.currentTarget).val();

    if (yearID) {
      let pageName = '';

      if ((typeof prestashop) !== 'undefined' && (typeof prestashop.page) !== 'undefined' && (typeof prestashop.page.page_name) !== 'undefined') {
        pageName = prestashop.page.page_name;
      }

      currentRequest = $.ajax({
        type: 'GET',
        dataType: 'json',
        beforeSend() {
          if (currentRequest != null) {
            currentRequest.abort();
            currentRequest = null;
          }
        },
        url: `/module/ukooparts/front?action=getModels&ajax=true&type=${typeID}&brand=${brandID}&year=${yearID}&page_name=${pageName}`,
        success(result) {
          $contextParent.find('select.ucpt-search__select--model').empty();
          $.each(result, (displacement, models) => {
            let displacementOptgroup = `<optgroup label="${displacement}">`;
            $.each(models, (k, v) => {
              displacementOptgroup += `<option value="${v.id}">${v.model}</option>`;
            });
            displacementOptgroup += '</optgroup>';
            $contextParent.find('select.ucpt-search__select--model').append(displacementOptgroup);
          });
          $contextParent.find('select.ucpt-search__select--model').prepend('<option></option>');
          $contextParent.find('select.ucpt-search__select--model').val(0);
          $contextParent.find('select.ucpt-search__select--model').prop('disabled', false).trigger('change.select2');

          $contextParent.removeClass('ucpt-spinner--is-spining');
        },
      });
    }
  });

  // == UX management (disabling, spinner)
  $(document).on('change', '.ucpt-search .ucpt-search__select', () => {
    $('.ucpt-search .ucpt-search__submit').removeAttr('disabled');
  });

  $(document).on('submit', '.ucpt-search .ucpt-search__form', (e) => {
    $(e.currentTarget).parents('.ucpt-search').addClass('ucpt-spinner--is-spining');
  });
});
