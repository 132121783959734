/* eslint-disable no-undef */
$(document).ready(() => {
  // Scroll management
  $(document).on('click', '.ucpt-glossary-list__link', (e) => {
    const target = $(e.currentTarget).data('target');

    if (window.matchMedia('(min-width:1024px)').matches) {
      ucptScrollDesktop(target);
    } else {
      ucptScrollMobile(target);
    }
  });

  // Search management
  $('.ucpt-input-search__select.ucpt-input-search__select--default').on('change', (e) => {
    const selectVal = $(e.currentTarget).val();
    const $glossary = $('.ucpt-glossary');
    const $glossaryItem = $('.ucpt-glossary__item');
    const $glossaryItemChild = $('.ucpt-glossary__item-child');
    const $glossaryItemChildSelected = $glossary.find(`.ucpt-glossary__item-child[data-id="${selectVal}"]`);

    if (selectVal) {
      $glossary.find($glossaryItem).hide();
      $glossary.find($glossaryItemChild).hide();

      $glossary.find($glossaryItemChildSelected).show().parents($glossaryItem).show();
    } else {
      $glossary.find($glossaryItem).show();
      $glossary.find($glossaryItemChild).show();
    }
  });
  $('.ucpt-input-search__select.ucpt-input-search__select--redirect').on('change', (e) => {
    const selectVal = $(e.currentTarget).val();

    if (selectVal) {
      window.location.href = selectVal;
      $(e.currentTarget).attr('disabled', 'disabled').trigger('change.select2');
      $(e.currentTarget).parents('.ucpt-input-search').after(`<p style="transform: translateY(-100%);"><i class="ucpt-text-primary small spinner-border" style="width: 1em; height: 1em; border-width: 0.2em;vertical-align: -0.1em;"></i> ${ucpt_input_search_txt_soon_redirected}</p>`);
    }
  });
});

function ucptScrollMobile(target) {
  // TODO[DEV] by ukoo_jordan [2022-11-02 16:39] -> Prévoir sélecteur dynamique en BO si header fixe
  let fixedHeaderMobileHeight = $('.header__fixed').outerHeight();

  if (typeof fixedHeaderMobileHeight === 'undefined') {
    fixedHeaderMobileHeight = 0;
  }
  const offTopTargetMob = $(target).offset().top;
  const offTopMob = offTopTargetMob - fixedHeaderMobileHeight - 10;
  $(window).scrollTop(offTopMob);
}

function ucptScrollDesktop(target) {
  // TODO[DEV] by ukoo_jordan [2022-11-02 16:39] -> Prévoir sélecteur dynamique en BO si header fixe
  let fixedHeaderDesktopHeight = $('#nav_wrapper').outerHeight();

  if (typeof fixedHeaderDesktopHeight === 'undefined') {
    fixedHeaderDesktopHeight = 0;
  }
  const offTopTarget = $(target).offset().top;
  const offTop = offTopTarget - fixedHeaderDesktopHeight - 10;
  $(window).scrollTop(offTop);
}
