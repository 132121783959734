/**
 * Prestashop module : Ukooparts
 *
 * @author Ukoo <contact@ukoo.fr>
 * @copyright Ukoo
 * @license Tous droits réservés / Le droit d'auteur s'applique
 * (All rights reserved / French copyright law applies)
 */

import {initUniversalTooltip, ucptScrollTo} from '../components/_utilities';

$(document).ready(() => {
  initUniversalTooltip();
});
