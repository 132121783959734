/**
 * Prestashop module : Ukooparts
 *
 * @author Ukoo <contact@ukoo.fr>
 * @copyright Ukoo
 * @license Tous droits réservés / Le droit d'auteur s'applique
 * (All rights reserved / French copyright law applies)
 */

/* eslint-disable no-undef */
$(document).ready(() => {
  initProductCompatibilitiesTable();
});

export function initProductCompatibilitiesTable() {
  const itemsPerPage = $('#ucpt-compatibilities-pagination').data('items_per_page');
  /* Ajax requests */
  let currentRequest = null;
  $('#ucpt-compatibilities-filters .ucpt-bselect').on('change', () => {
    const productId = $('#product_page_product_id').val();
    let manufacturerId = $('#ucpt-compatibilities-select-brand').val();
    let displacement = $('#ucpt-compatibilities-select-displacement').val();
    let modelId = $('#ucpt-compatibilities-select-model').val();
    let year = $('#ucpt-compatibilities-select-year').val();

    if (manufacturerId == null || manufacturerId === '' || manufacturerId === undefined) {
      manufacturerId = 0;
    }
    if (displacement == null || displacement === '' || displacement === undefined) {
      displacement = 0;
    }
    if (modelId == null || modelId === '' || modelId === undefined) {
      modelId = 0;
    }
    if (year == null || year === '' || year === undefined) {
      year = 0;
    }

    let pageName = '';

    if ((typeof prestashop) !== 'undefined' && (typeof prestashop.page) !== 'undefined' && (typeof prestashop.page.page_name) !== 'undefined') {
      pageName = prestashop.page.page_name;
    }

    currentRequest = $.ajax({
      type: 'GET',
      dataType: 'html',
      beforeSend() {
        if (currentRequest != null) {
          currentRequest.abort();
          currentRequest = null;
        }
        $('#ucpt-compatibilities-table').addClass('ucpt-spinner--is-spining').prepend('<div class="ucpt-spinner"></div>');
      },
      url: `/module/ukooparts/productcompatibility?ajax=true&productId=${productId}&manufacturerId=${manufacturerId}&modelId=${modelId}&displacement=${displacement}&year=${year}&page=1&perPage=${itemsPerPage}&page_name=${pageName}`,
      success(response) {
        const result = jQuery.parseJSON(response);
        $('#ucpt-compatibilities-results').html(result.data);
        $('#ucpt-compatibilities-pagination').html(result.pagination);

        $('#ucpt-compatibilities-table').removeClass('ucpt-spinner--is-spining').find('.ucpt-spinner').remove();
      },
    });
  });

  $('#ucpt-compatibilities-pagination').on('click', 'a', (e) => {
    e.preventDefault();
    const page = $(e.currentTarget).attr('page');
    const productId = $('#product_page_product_id').val();
    let manufacturerId = $('#ucpt-compatibilities-select-brand').val();
    let displacement = $('#ucpt-compatibilities-select-displacement').val();
    let modelId = $('#ucpt-compatibilities-select-model').val();
    let year = $('#ucpt-compatibilities-select-year').val();

    if (manufacturerId == null || manufacturerId === '' || manufacturerId === undefined) {
      manufacturerId = 0;
    }
    if (displacement == null || displacement === '' || displacement === undefined) {
      displacement = 0;
    }
    if (modelId == null || modelId === '' || modelId === undefined) {
      modelId = 0;
    }
    if (year == null || year === '' || year === undefined) {
      year = 0;
    }

    let pageName = '';

    if ((typeof prestashop) !== 'undefined' && (typeof prestashop.page) !== 'undefined' && (typeof prestashop.page.page_name) !== 'undefined') {
      pageName = prestashop.page.page_name;
    }

    currentRequest = $.ajax({
      type: 'GET',
      dataType: 'html',
      beforeSend() {
        if (currentRequest != null) {
          currentRequest.abort();
          currentRequest = null;
        }
        $('#ucpt-compatibilities-table').addClass('is--loading');
      },
      url: `/module/ukooparts/productcompatibility?ajax=true&productId=${productId}&manufacturerId=${manufacturerId}&modelId=${modelId}&displacement=${displacement}&year=${year}&page=${page}&perPage=${itemsPerPage}&page_name=${pageName}`,
      success(response) {
        const result = jQuery.parseJSON(response);
        $('#ucpt-compatibilities-results').html(result.data);
        $('#ucpt-compatibilities-pagination').html(result.pagination);

        $('#ucpt-compatibilities-table').removeClass('is--loading');
      },
    });
  });
}
