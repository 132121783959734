import {ucptScrollTo} from './_utilities';

$(document).ready(() => {
  quickaddResponsiveBreakpoint();
  // Spécifique : Permet de rendre fixe la recherche à facette de façon indépendante,
  // tout en rendant le quickaddfacets fixe également
  $(window).resize(() => {
    quickaddResponsiveBreakpoint();
  });

  $(document).on('click', '.ucpt-buttons__button--unselect-engine', () => {
    let pageName = '';

    if ((typeof prestashop) !== 'undefined' && (typeof prestashop.page) !== 'undefined' && (typeof prestashop.page.page_name) !== 'undefined') {
      pageName = prestashop.page.page_name;
    }

    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: `/module/ukooparts/front?action=unselectEngine&ajax=true&page_name=${pageName}`,
      success(result) {
        if (result.success) {
          const url = window.location.href.split('#');
          let newUrl = url[0];
          const anchor = url[1] ? `#${url[1]}` : '';
          [newUrl] = newUrl.split('?');

          const urlParams = new URLSearchParams(window.location.search);
          urlParams.append('needRedirect', `${!!($('.ucpt-quickadd').data('engine-active'))}`);
          let newParams = urlParams.toString();
          newParams = newParams ? `?${newParams}` : '';

          window.location.href = newUrl + newParams + anchor;
        } else if (result.message) {
          $('#ucpt-modal-quickadd .ucpt-buttons').before('<p class="alert alert-danger">result.message</p>');
        }
      },
    });
  });

  $(document).on('click', '.ucpt-quickadd--compatibility .ucpt-quickadd__link', (e) => {
    e.preventDefault();
    ucptScrollTo('#ucpt-compatibilities-table', -300);
  });

  // Check if facets is present, if not we add class on quickadd to deactivate border-radius
  if (!$('.ucpt-quickadd--default').find('+ #_desktop_search_filters_wrapper').length) {
    $('.ucpt-quickadd--default').addClass('ucpt-quickadd--fullradius');
  }
});

function quickaddResponsiveBreakpoint() {
  if (window.matchMedia('(min-width:1024px)').matches) {
    quickaddScrollFixed();
  } else {
    quickaddScrollUnfixed();
  }
}

function quickaddScrollFixed() {
  if ($('#_desktop_search_filters_wrapper').length && $('.ucpt-quickadd--default').length) {
    const $quickaddfacets = $('#left-column .ucpt-quickadd--default, #right-column .ucpt-quickadd--default');
    const quickaddfacetsHeight = $quickaddfacets.outerHeight();

    $quickaddfacets.addClass('must-fixed').find('+ div#_desktop_search_filters_wrapper').css('top', `calc(${quickaddfacetsHeight}px + 29px)`);
  }
}
function quickaddScrollUnfixed() {
  if ($('#_desktop_search_filters_wrapper').length && $('.ucpt-quickadd--default').length) {
    const $quickaddfacets = $('#left-column .ucpt-quickadd--default, #right-column .ucpt-quickadd--default');
    $quickaddfacets.removeClass('must-fixed').find('+ div#_desktop_search_filters_wrapper').css('top', '0');
  }
}
