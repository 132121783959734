/**
 * Prestashop module : Ukooparts
 *
 * @author Ukoo <contact@ukoo.fr>
 * @copyright Ukoo
 * @license Tous droits réservés / Le droit d'auteur s'applique
 * (All rights reserved / French copyright law applies)
 */

$(document).ready(() => {
  $('.ucpt-table form').submit((e) => {
    $(e.currentTarget).parents('.ucpt-table__list').addClass('ucpt-spinner--is-spining');
  });
});
