/**
 * Prestashop module : Ukooparts
 *
 * @author Ukoo <contact@ukoo.fr>
 * @copyright Ukoo
 * @license Tous droits réservés / Le droit d'auteur s'applique
 * (All rights reserved / French copyright law applies)
 */

/* eslint-disable no-undef */
import {initUniversalTooltip, ucptScrollTo} from '../components/_utilities';
import {updateProductListDOM} from '../components/_utilities';

$(document).ready(() => {
  const $ucptInfos = $('.ucpt-intro');
  const $ucptSelectsWithTooltip = $('.ucpt-select.ucpt-tooltip');
  const $ucptSubModelsSelect = $('#ucpt-select__sub-models');
  const $ucptModelAdd = $('#ucpt-intro__model-actions--add');
  const $ucptModelRemove = $('#ucpt-intro__model-actions--remove');

  // Select management
  initSelectToolTip($ucptSelectsWithTooltip);

  function initSelectToolTip($selectElement) {
    $selectElement.each(function () {
      $(this).tooltip({
        template: '<div class="ucpt-tooltip__tooltip tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
        placement: 'top',
        title: $(this).attr('title'),
      });
    });
  }

  let currentRequest = null;
  // compatibilities and data by model child (years/version)
  $ucptSubModelsSelect.on('change', (e) => {
    const modelId = $(e.target).val();

    // when switching of sub-model, we reset year choice
    $ucptInfos.attr('data-customer-year', '');
    $ucptInfos.attr('data-customer-invalidated-year', '');
    loadModelData(modelId, null);
  });

  // compatibilities by model year
  $(document).on('change', '#ucpt-select__years', (e) => {
    const modelId = $ucptInfos.attr('data-model-id');
    let customerModelYear = $(e.target).val();
    $ucptInfos.attr('data-customer-year', customerModelYear);
    $ucptInfos.attr('data-customer-invalidated-year', '');
    loadModelData(modelId, customerModelYear);
  });

  function loadModelData(modelId, customerModelYear) {
    // we remove opened tooltip to avoid display issue after ajax refresh
    $('.ucpt-tooltip__tooltip.show, .ucpt-tooltip__tooltip.in').remove();

    if (!customerModelYear) {
      customerModelYear = $ucptInfos.attr('data-customer-invalidated-year');
    }

    if (modelId === '') {
      return;
    }

    let pageName = '';

    if ((typeof prestashop) !== 'undefined' && (typeof prestashop.page) !== 'undefined' && (typeof prestashop.page.page_name) !== 'undefined') {
      pageName = prestashop.page.page_name;
    }

    currentRequest = $.ajax({
      type: 'GET',
      dataType: 'html',
      beforeSend() {
        if (currentRequest != null) {
          currentRequest.abort();
          currentRequest = null;
        }
        $('#content').addClass('ucpt-spinner--is-spining');
      },
      url: `/module/ukooparts/models?ajax=true&modelId=${modelId}&year=${customerModelYear}&page_name=${pageName}`,
      success(response) {
        const result = jQuery.parseJSON(response);

        window.history.pushState({path: result.model.url}, '', result.model.url);

        $('.js-model-years').html(result.availableModelYears);
        initSelectToolTip($('#ucpt-select__years'));

        $('.js-compatible-categories').html(result.cats);

        if ($('.js-compatible-product-list').length) {
          updateProductListDOM(result.listing)
          updateSortingLinksWithNewUrl(result.listing.sort_orders);
          initUniversalTooltip();
        }

        $('[data-model-id]').attr('data-model-id', result.model.id);

        const modelName = `${result.model.manufacturer.name} ${result.model.model} ${result.customerModelYear ? ` <span class="ucpt-text-primary">${result.customerModelYear}</span>` : ''}`;

        $('.js-model-img').attr({src: result.model.imageUrl.src, alt: modelName});

        if (result.profileType === 'deux_roues') {
          $('.js-model-startendyear').html(`${result.model.yearStart}-${result.model.yearEnd}`);

          if (result.customerModelYear) {
            $('.ucpt-section--products .js-model-startendyear').html('');
          }
        }

        $('.js-model-title').html(modelName);
        $('.js-model-description').html(result.model.description);
        $('.js-model-displacement--commercial').html(result.model.displacement_commercial);
        $('.js-model-displacement--technical').html(result.model.displacement);
        $('.js-model-attachments').html(result.modelAttachments);

        const $jsModelFeatures = $('.js-model-features');
        $jsModelFeatures.html(result.modelFeatures);
        if (result.modelFeatures !== '') {
          $jsModelFeatures.removeClass('d-none');
          $('.ucpt__model-features__btn-wrapper').removeClass('d-none');
        } else {
          $jsModelFeatures.addClass('d-none');
          $('.ucpt__model-features__btn-wrapper').addClass('d-none');
        }

        if (result.customerYearInvalidated) {
          $('.js-customer-invalidated-year-alert').removeClass('d-none').find('.js-customer-invalidated-year').text(result.customerYearInvalidated);
          $('.ucpt-intro__model-actions__wrapper').addClass('d-none');
          $ucptInfos.attr('data-customer-invalidated-year', result.customerYearInvalidated);
        } else {
          $('.js-customer-invalidated-year-alert').addClass('d-none');
          $('.ucpt-intro__model-actions__wrapper').removeClass('d-none');
          $ucptInfos.attr('data-customer-invalidated-year', '');
        }

        if (result.modelAlreadyOwnedByCustomer) {
          $ucptModelAdd.addClass('d-none');
          $ucptModelRemove.removeClass('d-none');
        } else {
          $ucptModelAdd.removeClass('d-none');
          $ucptModelRemove.addClass('d-none');
        }

        $('#content').removeClass('ucpt-spinner--is-spining');
      },
    });
  }

  $ucptModelAdd.on('click', (e) => {
    e.preventDefault();

    const modelId = $ucptInfos.attr('data-model-id');

    if (!modelId) {
      // todo : display error message
      return;
    }

    let customerModelYear = $ucptInfos.attr('data-customer-year');

    if (!customerModelYear) {
      customerModelYear = $ucptInfos.attr('data-customer-invalidated-year');
    }

    let pageName = '';

    if ((typeof prestashop) !== 'undefined' && (typeof prestashop.page) !== 'undefined' && (typeof prestashop.page.page_name) !== 'undefined') {
      pageName = prestashop.page.page_name;
    }
    currentRequest = $.ajax({
      type: 'POST',
      dataType: 'json',
      beforeSend() {
        if (currentRequest != null) {
          currentRequest.abort();
          currentRequest = null;
        }
      },
      url: `/module/ukooparts/front?action=addToGarage&ajax=true&model=${modelId}&year=${customerModelYear}&page_name=${pageName}`,
      success() {
        // todo handle response
        $ucptModelAdd.addClass('d-none');
        $ucptModelRemove.removeClass('d-none');
      },
    });
  });

  $ucptModelRemove.on('click', (e) => {
    e.preventDefault();

    const modelId = $ucptInfos.attr('data-model-id');

    if (!modelId) {
      // todo : display error message
      return;
    }

    let customerModelYear = $ucptInfos.attr('data-customer-year');

    if (!customerModelYear) {
      customerModelYear = $ucptInfos.attr('data-customer-invalidated-year');
    }

    let pageName = '';

    if ((typeof prestashop) !== 'undefined' && (typeof prestashop.page) !== 'undefined' && (typeof prestashop.page.page_name) !== 'undefined') {
      pageName = prestashop.page.page_name;
    }
    currentRequest = $.ajax({
      type: 'POST',
      dataType: 'json',
      beforeSend() {
        if (currentRequest != null) {
          currentRequest.abort();
          currentRequest = null;
        }
      },
      url: `/module/ukooparts/front?action=removeFromGarage&ajax=true&model=${modelId}&year=${customerModelYear}&page_name=${pageName}`,
      success() {
        // todo handle response
        $ucptModelRemove.addClass('d-none');
        $ucptModelAdd.removeClass('d-none');
      },
    });
  });

  // Features collapse
  $(document).on('click', '.ucpt-features__title', (e) => {
    e.stopPropagation();
    const titleTarget = $(e.currentTarget).data('target');
    $(titleTarget).collapse('toggle');
    $(e.currentTarget).toggleClass('open');
  });

  // Prevents scroll to the top from the default theme's updateProductList handler, then scroll to the product list top
  let currentTop = $(window).scrollTop();
  $(document).on('change', '#select-sort-order', (e) => {
    currentTop = $(window).scrollTop();
  });
  $(document).on('click', '.products-pagination .js-search-link, .products-pagination [rel="next"], .products-pagination [rel="prev"]', (e) => {
    currentTop = $(window).scrollTop();
  });

  prestashop.on('updateProductList', (data) => {
    $(document).scrollTop(currentTop);
    ucptScrollTo('#js-product-list-top');
  });
});

function updateSortingLinksWithNewUrl(newSortOrders = '') {
  let newSortOrdersHtml = '';
  $.each(newSortOrders, (k, v) => {
    newSortOrdersHtml += `<a href="${v.url}" class="list-group-item list-group-item-action` + (v.current ? ' active' : '') + `">${v.label}</a>`;
  });

  $('#sortModal .list-group').html(newSortOrdersHtml);
}
